<template>
  <div>
    <h3 class="my-6">Informações da Transferência</h3>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col md="6" cols="12">
          <app-text-field v-model="form.description" label="Descrição" />
        </v-col>
      </v-row>
      <h3 class="my-6">Empresas</h3>
      <v-row>
        <v-col md="3" cols="12">
          <CompanySelect
            v-model="form.company_origin"
            label="Empresa de Origem"
            :exclude="form.company_destination"
            :filter="(company) => company.status == 1"
          />
        </v-col>
        <v-col md="3" cols="12">
          <CompanySelect
            v-model="form.company_destination"
            label="Empresa de Destino"
            :exclude="form.company_origin"
            :filter="(company) => company.status == 1"
          />
        </v-col>
      </v-row>

      <h3 class="mb-3 mt-6">Produtos</h3>
      <v-row>
        <v-col md="4" cols="12">
          <ProductSearch
            :rules="productRules"
            v-model="transfer_product.product"
          />
        </v-col>
        <v-col md="2" cols="9">
          <app-number-field
            v-model="transfer_product.quantity"
            label="Quantidade"
          />
        </v-col>
        <v-col md="2" cols="2">
          <v-btn
            v-if="productIndex == null"
            @click="addProduct()"
            class="text-capitalize"
            block
            color="primary"
            :disabled="!transfer_product.quantity > 0"
          >
            <app-icon>add</app-icon>
            <span class="d-none d-md-block">Adicionar</span>
          </v-btn>
          <v-btn
            v-if="productIndex != null"
            @click="updateProduct()"
            block
            class="text-capitalize"
            color="primary"
          >
            <app-icon>cached</app-icon>
            <span class="d-none d-md-block">Atualizar</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="hasProducts">
        <v-col cols="12">
          <v-data-table
            dense
            disable-sort
            hide-default-footer
            :items-per-page="-1"
            :server-items-length="-1"
            :headers="headers"
            :items="form.stock_transfer_products"
          >
            <template v-slot:[`item.quantity`]="{ item }">
              <span>{{ $format.decimal(item.quantity) }}</span>
            </template>
            <template v-slot:[`item.action`]="{ index }">
              <div class="d-flex">
                <v-btn icon color="primary" @click="removeProduct(index)">
                  <app-icon>delete</app-icon>
                </v-btn>
                <v-btn icon color="primary" @click="editProduct(index)">
                  <app-icon>edit</app-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <br />
      <v-row class="mt-15">
        <v-col md="6">
          <v-textarea
            outlined
            label="Obervações"
            rows="2"
            v-model="form.observations"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col class="text-md-right">
        <v-btn
          @click="$router.go(-1)"
          class="text-capitalize mr-2"
          text
          color="secondary"
          >Voltar</v-btn
        >
        <v-btn
          @click="handleSave('draft')"
          class="text-capitalize mr-2"
          color="primary"
          outlined
          >Salvar Rascunho</v-btn
        >
        <v-btn
          @click="handleSave('confirmed')"
          class="text-capitalize"
          color="primary"
          >Salvar</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import ProductSearch from "@/components/product/ui/ProductSearch.vue";
export default {
  components: { CompanySelect, ProductSearch },
  data() {
    return {
      productRules: [(v) => !!v || "O Selecione é um produto"],
      valid: true,

      productIndex: null,
      headers: [
        {
          width: "80%",
          align: "start",
          text: "Produto",
          value: "product.name",
        },
        {
          width: "20%",
          align: "center",
          text: "Quantidade",
          value: "quantity",
        },
        { align: "end", text: "", value: "action" },
      ],
      form: {},

      transfer_product: {
        quantity: 0,
        product: {},
      },
      baseForm: {
        description: null,
        company_origin: {},
        company_destination: {},
        stock_transfer_products: [],
        observations: null,
      },
    };
  },

  created() {
    this.resetForm();
    if (!this.isInsert) {
      this.$loading.start();
      this.$http
        .show("stock/stock-transfer", this.$route.params.id)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.transfer));
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    }
  },

  computed: {
    btnTitle() {
      return this.product_index === null ? "Adicionar" : "Editar";
    },
    isInsert() {
      return !this.$route.params.id;
    },
    hasProducts() {
      return this.form.stock_transfer_products.length > 0;
    },
  },
  methods: {
    addProduct() {
      if (this.$refs.form.validate()) {
        this.form.stock_transfer_products.push(this.transfer_product);
        this.resetProduct();
        this.$refs.form.resetValidation();
      }
    },

    removeProduct(product_index) {
      this.$delete(this.form.stock_transfer_products, product_index);
    },

    editProduct(product_index) {
      this.productIndex = product_index;
      this.transfer_product = JSON.parse(
        JSON.stringify(this.form.stock_transfer_products[product_index])
      );
    },

    updateProduct() {
      this.$set(
        this.form.stock_transfer_products,
        this.productIndex,
        this.transfer_product
      );
      this.$refs.form.resetValidation();

      this.resetProduct();
    },

    resetProduct() {
      this.productIndex = null;
      this.transfer_product = {
        quantity: 0,
        product: {},
      };
    },
    resetForm() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },
    handleSave(status) {
      this.$loading.start();
      this.form.status = status;

      if (this.isInsert) {
        this.store(status);
      } else {
        this.update(status);
      }
    },

    setTransferProduct(product) {
      this.transfer_product.product = product;

      this.transfer_product.product_variant = product.product_variants[0];

      this.transfer_product.product_company_origin =
        product.product_variants[0].product_companies.find((row) => {
          return row.company_id == this.form.company_origin.id;
        });

      this.transfer_product.product_company_destination =
        product.product_variants[0].product_companies.find((row) => {
          return row.company_id == this.form.company_destination.id;
        });
    },

    store(status) {
      this.form.status = status;
      this.$http
        .store("stock/stock-transfer", this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    update(status) {
      this.form.status = status;

      this.$http
        .update("stock/stock-transfer", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$loading.finish();
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>